import * as React from 'react';
import * as styles from '../pages/index.module.scss';

import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image';

import Brand from '../components/brand';
import Button from '../components/button';
import Callout from '../components/v2/callout';
import EmbedButton from '../components/embed-button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Footer from '../components/v2/footer';
import Header from '../components/v2/header';
import SEO from '../components/seo';
import Section from '../components/section';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { graphql } from 'gatsby';

const Logo = ({ id }) => {
  switch (id) {
    case 'shopify':
      return (
        <svg
          width="110"
          height="125"
          viewBox="0 0 110 125"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M95.9 23.9c-.1-.6-.6-1-1.1-1s-9.3-.2-9.3-.2-7.4-7.2-8.1-7.9-2.2-.5-2.7-.3c0 0-1.4.4-3.7 1.1-.4-1.3-1-2.8-1.8-4.4-2.6-5-6.5-7.7-11.1-7.7-.3 0-.6 0-1 .1-.1-.2-.3-.3-.4-.5C54.7.9 52.1-.1 49 0c-6 .2-12 4.5-16.8 12.2-3.4 5.4-6 12.2-6.8 17.5-6.9 2.1-11.7 3.6-11.8 3.7-3.5 1.1-3.6 1.2-4 4.5-.3 2.5-9.5 73-9.5 73l76.4 13.2 33.1-8.2c-.1-.1-13.6-91.4-13.7-92m-28.7-7.1c-1.8.5-3.8 1.2-5.9 1.8 0-3-.4-7.3-1.8-10.9 4.5.9 6.7 6 7.7 9.1m-10 3.1c-4 1.2-8.4 2.6-12.8 3.9 1.2-4.7 3.6-9.4 6.4-12.5 1.1-1.1 2.6-2.4 4.3-3.2 1.8 3.5 2.2 8.4 2.1 11.8M49.1 4q2.1 0 3.6.9c-1.6.9-3.2 2.1-4.7 3.7-3.8 4.1-6.7 10.5-7.9 16.6-3.6 1.1-7.2 2.2-10.5 3.2C31.7 18.8 39.8 4.3 49.1 4"
            fill="#95BF47"
          />
          <path
            d="M94.8 22.9c-.5 0-9.3-.2-9.3-.2s-7.4-7.2-8.1-7.9c-.3-.3-.6-.4-1-.5V124l33.099-8.2s-13.5-91.3-13.6-92c-.1-.5-.6-.9-1.1-.9"
            fill="#5E8E3E"
          />
          <path
            d="m58 39.9-3.8 14.4s-4.3-2-9.4-1.6c-7.5.5-7.5 5.2-7.5 6.4.4 6.4 17.3 7.8 18.3 22.9.7 11.9-6.3 20-16.4 20.6-12.2.8-18.9-6.4-18.9-6.4l2.6-11s6.7 5.1 12.1 4.7c3.5-.2 4.8-3.1 4.7-5.1-.5-8.4-14.3-7.9-15.2-21.7-.7-11.6 6.9-23.4 23.7-24.4 6.5-.5 9.8 1.2 9.8 1.2"
            fill="#fff"
          />
        </svg>
      );
    case 'squarespace':
      return (
        <svg
          width="125"
          height="102"
          viewBox="0 0 125 102"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="m26.155 54 39.497-39.981a14.04 14.04 0 0 1 10.06-4.21c3.803 0 7.374 1.492 10.055 4.208l3.077 3.115L95 10.901l-3.077-3.115C87.6 3.41 81.843 1 75.709 1c-6.13.001-11.888 2.411-16.211 6.789L20 47.769z"
            fill="#000"
          />
          <path
            d="m79 27.3-6.185-6.223L33.132 61c-5.573 5.606-14.64 5.607-20.211.003-5.573-5.606-5.573-14.729 0-20.335L47.164 6.221 40.978 0 6.737 34.446c-8.983 9.037-8.983 23.742 0 32.777C11.081 71.593 16.864 74 23.024 74c6.161 0 11.948-2.41 16.292-6.779zm39.262 7.476C113.92 30.406 108.136 28 101.973 28c-6.161.001-11.948 2.408-16.291 6.779L46 74.7l6.182 6.222L91.867 41c5.574-5.606 14.64-5.605 20.211-.002 2.695 2.711 4.179 6.32 4.179 10.166s-1.484 7.457-4.179 10.168L77.837 95.78l6.185 6.22 34.24-34.445c8.984-9.037 8.984-23.742 0-32.779"
            fill="#000"
          />
          <path
            d="M98.846 48 59.349 87.981c-5.548 5.613-14.571 5.616-20.116.002l-3.077-3.115L30 91.099l3.077 3.115C37.4 98.59 43.157 101 49.292 101c6.13-.001 11.887-2.411 16.211-6.789L105 54.231z"
            fill="#000"
          />
        </svg>
      );
    default:
      return null;
  }
};

const LandingPageTemplate = ({ pageContext, data }) => {
  const { metaTitle, metaDescription, title, subtitle, btnText, logo } = pageContext;

  const quizImage = getImage(data.quizImage);

  return (
    <>
      <SEO title={`${metaTitle}`} description={`${metaDescription}`} noIndex={true} />
      <Header btnTheme="primary" />
      <main className={styles.index}>
        <Section theme="white" style={{ padding: `5rem 0`, marginTop: `5rem` }}>
          <div className="row align-items-center">
            <div className="col-lg-7">
              {logo && (
                <div className="row justify-content-center justify-content-lg-start">
                  <div className="col-auto">
                    <div className={styles.logos}>
                      <div className={styles.logoContainer}>
                        <div
                          className={styles.logo}
                          style={{ backgroundPosition: `0 -1280px` }}></div>
                      </div>
                      <div className={styles.logoContainer}>
                        {logo === 'squarespace' ? <Logo id="squarespace" /> : null}
                        {logo === 'shopify' ? <Logo id="shopify" /> : null}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <h1 className="color-black" style={{ marginBottom: `2.5rem` }}>
                {title}
              </h1>
              <h2 className="text-body color-muted-foreground">{subtitle}</h2>
              <div className={styles.buttons}>
                <form method="get" action="https://ai.tryinteract.com/create">
                  <div className={styles.inputContainer}>
                    <input
                      type="text"
                      placeholder="https://www.mysite.com"
                      name="website_url"></input>
                    <Button theme="primary" size="large" type="submit" style={{ gap: '12px' }}>
                      Get started for free <FontAwesomeIcon icon={faArrowRight} />
                    </Button>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-5">
              <div className={styles.customerCards}>
                <div
                  className={[styles.customerCard, 'order-0 order-md-4'].join(' ')}
                  style={{ '--brand-color': '#f1401d' }}>
                  <StaticImage
                    src="../images/examples/advicewitherin.png"
                    alt="Advice With Erin"
                    placeholder="blurred"
                  />
                  <div className={styles.customerCardBackground}></div>
                  <div className={styles.customerCardSolidBackground}></div>
                  <div className={styles.customerCardContent}>
                    <p>Advice With Erin</p>
                    <p>Creator</p>
                    <Button
                      theme="white"
                      href="https://advicewitherin.com/quiz"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                  <div className={styles.customerCardOverlay}>
                    <p>The Career Type Quiz</p>
                    <Button
                      theme="white"
                      size="small"
                      href="https://advicewitherin.com/quiz"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                </div>
                <div className={styles.customerCard} style={{ '--brand-color': '#6070b4' }}>
                  <StaticImage
                    src="../images/examples/camperdido.png"
                    alt="Cam Perdido"
                    placeholder="blurred"
                  />
                  <div className={styles.customerCardBackground}></div>
                  <div className={styles.customerCardSolidBackground}></div>
                  <div className={styles.customerCardContent}>
                    <p>Cam Perdido</p>
                    <p>Business Coach</p>
                    <Button
                      theme="white"
                      href="https://www.digitalgardengirl.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                  <div className={styles.customerCardOverlay}>
                    <p>What's Your Garden CEO Style?</p>
                    <Button
                      theme="white"
                      size="small"
                      href="https://www.digitalgardengirl.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                </div>
                <div className={styles.customerCard} style={{ '--brand-color': '#858791' }}>
                  <StaticImage
                    src="../images/examples/henryshouseofcoffee.png"
                    alt="Henry's House of Coffee"
                    placeholder="blurred"
                  />
                  <div className={styles.customerCardBackground}></div>
                  <div className={styles.customerCardSolidBackground}></div>
                  <div className={styles.customerCardContent}>
                    <p>Henry's House of Coffee</p>
                    <p>E-Commerce</p>
                    <Button
                      theme="white"
                      href="https://henryshouseofcoffee.com/pages/coffee-quiz"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                  <div className={styles.customerCardOverlay}>
                    <p>Coffee Matchmaker</p>
                    <Button
                      theme="white"
                      size="small"
                      href="https://henryshouseofcoffee.com/pages/coffee-quiz"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                </div>
                <div className={styles.customerCard} style={{ '--brand-color': '#4b8471' }}>
                  <StaticImage
                    src="../images/examples/toddlerscanread.png"
                    alt="Toddler Can Read"
                    placeholder="blurred"
                  />
                  <div className={styles.customerCardBackground}></div>
                  <div className={styles.customerCardSolidBackground}></div>
                  <div className={styles.customerCardContent}>
                    <p>Toddlers Can Read</p>
                    <p>Education</p>
                    <Button
                      theme="white"
                      href="https://www.toddlersread.com/pages/advanced-reading-quiz"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                  <div className={styles.customerCardOverlay}>
                    <p>How to Help Your Child Become a Better Reader</p>
                    <Button
                      theme="white"
                      size="small"
                      href="https://www.toddlersread.com/pages/advanced-reading-quiz"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'var(--brand-color)' }}>
                      Take Quiz
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row align-items-center justify-content-center">
            <div className="col-lg-12 col-xl-10">
              <div className="row">
                <div className="col">
                  <p className="text-body color-black text-center">Trusted by leading businesses</p>
                </div>
              </div>
              <div className="row align-items-center justify-content-center justify-content-lg-between">
                <Brand id="gretchen-rubin" className={`col-auto ${styles.brand}`} />
                <Brand id="jennakutcher-color" className={`col-auto ${styles.brand}`} />
                <Brand id="melyssagriffin-color" className={`col-auto ${styles.brand}`} />
                <Brand id="redcross-color" className={`col-auto ${styles.brand}`} />
                <Brand id="atlassian" className={`col-auto ${styles.brand}`} />
                <Brand id="lush-color" className={`col-auto ${styles.brand}`} />
              </div>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row">
            <div className="col-12 text-center">
              <p className="heading-lg color-black" style={{ marginBottom: '1rem' }}>
                Empowering businesses since 2014 with proven results:
              </p>
            </div>
          </div>
          <div
            className={`row no-gutters align-items-center justify-content-center ${styles.metrics}`}>
            <div className="col-md-4">
              <p className={styles.number}>1.2B</p>
              <p className={styles.label}>quiz impressions</p>
            </div>
            <div className="col-md-4">
              <p className={styles.number}>400M</p>
              <p className={styles.label}>quizzes completed</p>
            </div>
            <div className="col-md-4">
              <p className={styles.number}>85M</p>
              <p className={styles.label}>leads generated</p>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div
            className="row no-gutters align-items-stretch justify-content-center"
            style={{ borderRadius: '32px', overflow: 'hidden', background: `#edf0ff` }}>
            <div className="col-lg-6 col-xl-5 order-lg-1 text-md-center">
              <div className={styles.quizContainer}>
                <p
                  className="heading-xs-upper color-purple-500"
                  style={{ margin: '0', fontWeight: '700', fontSize: '14px' }}>
                  Take Quiz
                </p>
                <p
                  className="heading-lg color-primary-950 font-style-golos"
                  style={{ margin: `0`, marginTop: '1rem', fontWeight: '700' }}>
                  What quiz should you make for your business?
                </p>
                <p className="heading-sm color-primary-950" style={{ margin: `1rem 0 2rem 0` }}>
                  Find your perfect lead generation quiz. Unlock the power of a 40% conversion rate
                  that quizzes offer by finding the exact quiz type that will work for your
                  business.
                </p>
                <EmbedButton id="65414c9de4c0cb0014c537e5" disableCover={true}>
                  Find out now
                </EmbedButton>
              </div>
            </div>
            <div className="col-md-8 col-lg-6 col-xl-7 order-lg-0 text-center">
              <GatsbyImage image={quizImage} alt="Interact template screenshots" />
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-auto d-none d-sm-block">
              <div className={styles.integrations}>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Klaviyo"
                    style={{ backgroundPosition: '0px -920px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Zapier"
                    style={{ backgroundPosition: '0px -1760px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Mailchimp"
                    style={{ backgroundPosition: '0px -1000px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Interact"
                    style={{ backgroundPosition: '0px -800px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Kit"
                    style={{ backgroundPosition: '0px -280px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Flodesk"
                    style={{ backgroundPosition: '0px -520px' }}></div>
                </div>
                <div className={styles.integrationItem}>
                  <div
                    className={styles.icon}
                    aria-label="Constant Contact"
                    style={{ backgroundPosition: '0px -240px' }}></div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <h3 className="heading-xl color-black">Integrate quizzes with your existing tools</h3>
            </div>
            <div className="col-lg-6 col-xl-6 text-center">
              <p className="heading-sm">
                Identify the needs of your customers based on an assessment so they can see clearly
                how to begin working with your business.
              </p>
              <Button
                theme="outline"
                size="large"
                type="internal"
                href="/integrations"
                style={{ marginTop: '2rem' }}>
                Browse integrations
              </Button>
            </div>
          </div>
        </Section>
        <Section theme="default" style={{ padding: `2rem 0 5rem 0` }}>
          <div className="row justify-content-center">
            <div className="col-lg-10 col-xl-8 text-center">
              <p className="heading-xl color-black">Our customers love Interact quizzes</p>
            </div>
          </div>
          <div className="row justify-content-center" style={{ marginTop: '3rem' }}>
            <div className="col-12 col-lg-10 col-xl-8">
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  Well, since my own email list is growing by 5,000 subscribers each and every week,
                  I can confidently say that having a fun quiz plays a big role in generating these
                  kind of numbers.
                  <br />
                  <br />
                  My quiz has also been one of the biggest contributors in increasing my unpaid
                  email list growth, which I love!
                </p>
                <div className={styles.caption}>
                  <StaticImage
                    src="../images/testimonials/testimonials-9.jpg"
                    alt="Jenna Kutcher"
                    placeholder="blurred"
                    width={150}
                    height={150}
                    style={{
                      borderRadius: `50%`,
                      width: '72px',
                      borderRadius: `50%`,
                      transform: `translateZ(0)`,
                    }}
                  />
                  <p className="text-body color-black" style={{ marginTop: '0.5rem' }}>
                    Jenna Kutcher
                  </p>
                  <p className="text-body">NYT Best-Selling Author</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-10 col-xl-8">
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  On the custom website design side of my business, I created an Interact quiz
                  almost two years ago for one of my previous marketing clients. That quiz has
                  generated over 10,000 new email subscribers in the past two years! Their quiz has
                  a 58% percent completion rate.
                  <br />
                  <br />
                  Plus, the subscribers who come from their quiz are always very engaged and tend to
                  open emails at a higher rate than other subscribers. The same is true for my own
                  business.
                </p>
                <div className={styles.caption}>
                  <StaticImage
                    src="../images/testimonials/testimonials-8.jpg"
                    alt="Elizabeth McCravy"
                    placeholder="blurred"
                    width={150}
                    height={150}
                    style={{
                      borderRadius: `50%`,
                      width: '72px',
                      borderRadius: `50%`,
                      transform: `translateZ(0)`,
                    }}
                  />
                  <p className="text-body color-black" style={{ marginTop: '0.5rem' }}>
                    Elizabeth McCravy
                  </p>
                  <p className="text-body">ShowIt Website Designer</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-10 col-xl-8">
              <div className={styles.testimonial}>
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <FontAwesomeIcon icon={faStar} className={styles.quote} />
                <p className="text-body color-black">
                  Quizzes are an amazing way to convert people. You get to know so much about an
                  individual person, or even about your people, and the trends that exist within
                  your audience.
                </p>
                <div className={styles.caption}>
                  <StaticImage
                    src="../images/testimonials/testimonials-7.jpg"
                    alt="Minessa Konecky"
                    placeholder="blurred"
                    width={150}
                    height={150}
                    style={{
                      borderRadius: `50%`,
                      width: '72px',
                      borderRadius: `50%`,
                      transform: `translateZ(0)`,
                    }}
                  />
                  <p className="text-body color-black" style={{ marginTop: '0.5rem' }}>
                    Minessa Konecky
                  </p>
                  <p className="text-body">CEO, Research Administration Expert</p>
                </div>
              </div>
            </div>
          </div>
        </Section>
        <Callout />
      </main>
      <Footer />
    </>
  );
};

export default LandingPageTemplate;

export const pageQuery = graphql`
  query {
    quizImage: file(relativePath: { eq: "landing-quiz.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, transformOptions: { cropFocus: CENTER })
      }
    }
  }
`;
